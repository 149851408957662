import { addons } from '@storybook/addons'
import { startCase } from 'lodash'
import sanaTheme from './sana-theme'

addons.setConfig({
  theme: sanaTheme,
  sidebar: {
    renderLabel: ({ name, type }) => {
      return startCase(name)
    },
  },
})
