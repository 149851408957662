import { create } from '@storybook/theming/create'

export default create({
  base: 'dark',
  brandTitle: 'Sana UI',
  brandUrl: 'https://github.com/sanalabs/sierra-platform/tree/master/yarn-project-root/ui',
  brandImage:
    'https://sdl-assets.ams3.cdn.digitaloceanspaces.com/sdl/2021/11/Sdl_Web_Sana_Labs_Lockup_4_1920x1080-600x338.jpg',

  appBg: '#000',
  appContentBg: '#000',
  appBorderColor: '#111',
  appBorderRadius: 6,

  textColor: '#fff',
  textInverseColor: '#000',

  barTextColor: '#fff',
  barSelectedColor: '#ccc',
  barBg: '#000',

  inputBg: '#fff',
  inputBorder: 'white',
  inputTextColor: '#000',
  inputBorderRadius: 6,
})
